var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.btnsPos ==='right')?_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.scY > 300 )?_c('div',{style:({
            'position': 'fixed',
            'bottom':  '20px',
            'right': '40px',
            'z-index': 1}),attrs:{"id":"helpers-right"}},[_c('v-icon',{staticClass:"mx-2",attrs:{"large":"","ripple":true},on:{"click":function($event){return _vm.toTop()}}},[_vm._v("mdi-chevron-double-up")])],1):_vm._e()]):_vm._e(),(_vm.btnsPos ==='left')?_c('transition',[(_vm.scY > 300)?_c('div',{style:({
            'position': 'fixed',
            'bottom':  '20px',
            'left': '5px',
            'z-index': 1}),attrs:{"id":"helpers-left"}},[_c('v-icon',{staticClass:"mx-2",staticStyle:{"font-size":"60px"},attrs:{"color":"white","large":"","ripple":true},on:{"click":function($event){return _vm.toTop()}}},[_vm._v("mdi-chevron-double-up")])],1):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }