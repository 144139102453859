<template>
    <authorization></authorization>
</template>

<script>
    import Authorization from "../../views/Authorization";
    export default {
        name: "AuthorizationStackPanel",
        components: {Authorization}
    }
</script>

<style scoped>

</style>
