var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height elevation-0",attrs:{"color":"white"}},[(_vm.products && _vm.products.length > 0)?_c('div',{staticClass:"mx-4"},_vm._l((_vm.products),function(item){return _c('v-list-item',{key:item.id,attrs:{"dense":""}},[_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('HorizontalCard',_vm._b({},'HorizontalCard',{
                            idx: item.id,
                            title: item.title,
                            price: item.price,
                            source: item.images[0],
                            elevation: 0
                            },false))],1),_c('v-col',{staticClass:"d-flex flex-row justify-center",attrs:{"cols":"2"}},[_c('v-row',{staticClass:"d-flex flex-row align-center justify-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","large":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-trash-can-outline ")])]}}],null,true)},[_c('span',[_vm._v(" Удалить ")])])],1)],1)],1)],1)}),1):_vm._e(),(!_vm.products || _vm.products.length === 0)?_c('v-card-title',{staticClass:"d-flex flex-column align-center justify-center"},[_vm._v(" Нет избранных товаров! ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }