var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height",attrs:{"color":"white"}},[_c('v-text-field',{staticClass:"mx-2 rounded-xxl",attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Поиск","outlined":"","dense":"","loading":_vm.loading},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),(_vm.searchInfo && _vm.searchInfo.length > 0)?_c('div',{staticClass:"mx-4"},[_c('v-list',{staticStyle:{"height":"80vh","overflow-y":"auto"}},_vm._l((_vm.searchInfo),function(item){return _c('v-list-item',{key:item['@Product']},[_c('v-sheet',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.goToProduct(item['@Product'])}}},[_c('HorizontalCard',_vm._b({},'HorizontalCard',{
                           idx: item['@Product'],
                           title: item['Title'],
                           specialPrice: item['specialPrice'],
                           sale: item['sale'],
                           salePercent: item['salePercent'],
                           price: item['Properties'] ? item['Properties'][0] : '0',
                           source: item['Images'] && item['Images'].length > 0 ? item['Images'][0] : '',
                           elevation: 0
                        },false))],1),_c('v-divider')],1)}),1)],1):_vm._e(),(_vm.searchString && _vm.searchString.length > 0 && !_vm.loading && (!_vm.searchInfo || _vm.searchInfo.length === 0))?_c('v-card-title',{staticClass:"d-flex flex-column align-center justify-center text-break text-center"},[_vm._v(" По вашему запросу ничего не найдено! ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }