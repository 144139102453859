var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height",attrs:{"color":"white"}},[(_vm.products && _vm.products.length > 0)?_c('div',{staticClass:"mx-4"},[_c('v-virtual-scroll',{staticStyle:{"overflow-x":"hidden"},attrs:{"bench":20,"items":_vm.products,"height":"800","item-height":"130"},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-list-item',{key:item.id},[_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('HorizontalCard',_vm._b({},'HorizontalCard',{
                            idx: item.id,
                            title: item.title,
                            price: item.price,
                            amount: item.amount,
                            source: item.images[0]
                            },false))],1),_c('v-col',{staticClass:"d-flex flex-row justify-center",attrs:{"cols":"2"}},[_c('v-row',{staticClass:"d-flex flex-row align-center justify-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","large":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-trash-can-outline ")])]}}],null,true)},[_c('span',[_vm._v(" Удалить ")])])],1)],1)],1)],1),_c('v-divider')]}}],null,false,2506195743)})],1):_vm._e(),(!_vm.products || _vm.products.length === 0)?_c('v-card-title',{staticClass:"d-flex flex-column align-center justify-center"},[_vm._v(" Нет товаров для сравнения! ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }