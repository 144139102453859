var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{ref:"menu",attrs:{"offset-y":"","bottom":"","rounded":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({staticStyle:{"cursor":"text"},attrs:{"dark":false,"dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify","append-icon":_vm.hasMore ? 'mdi-text-search' : '',"single-line":"","outlined":"","rounded":"","placeholder":"Поиск по товарам","color":"black","loading":_vm.loading},on:{"click:append":_vm.showSearchPanel,"click":function($event){_vm.window.innerWidth <= 1280 ? _vm.showSearchPanel() : ''}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},on))]}}])},[(!_vm.iMobile)?_c('div',[(_vm.noData)?_c('v-card',{on:{"click":function($event){return _vm.showSearchPanel()}}},[_c('v-card-subtitle',[_vm._v(" По вашему запросу ничего не найдено ")])],1):_vm._e(),_vm._l((this.searchInfo),function(item,index){return _c('v-card',{key:index,staticClass:"adaptive-search",on:{"click":function($event){return _vm.goToProduct(item['@Product'])}}},[_c('HorizontalCard',_vm._b({},'HorizontalCard',{
                   idx: item['@Product'],
                   title: item['Title'],
                   price: item['Properties'] ? item['Properties'][0] : '0',
                   specialPrice: item['specialPrice'],
                   sale: item['sale'],
                   salePercent: item['salePercent'],
                   source: item['Images'] && item['Images'].length > 0 ? item['Images'][0] : '',
                   toSearch: true,
                   elevation: 0
                },false)),_c('v-divider')],1)}),(_vm.hasMore)?_c('v-card',{on:{"click":function($event){return _vm.showSearchPanel()}}},[_c('v-card-title',[_vm._v(" Показать ещё "+_vm._s(_vm.hasMore)+" "+_vm._s(_vm.calculateName(_vm.hasMore))+" ")])],1):_vm._e()],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }