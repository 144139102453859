import { render, staticRenderFns } from "./StackPanelController.vue?vue&type=template&id=bfde5250&scoped=true&"
import script from "./StackPanelController.vue?vue&type=script&lang=js&"
export * from "./StackPanelController.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfde5250",
  null
  
)

export default component.exports